<template lang="">
    <div class="orderDetail">
         <div class="headers">
            <span @click="goUp()"></span>
            <span>拖车订单详情</span>
            <span @click="islogout">退出</span>
        </div>
        <div class="orderInfo">
            <p class="orderInfo_p">托运订单信息：</p>
            <div class="InfoList">
                <p>订单号码：{{ info.order_number }}</p>
                <p>使用车辆：{{ info.plate_number }}——{{info.brand_model }}</p>
                <p>预约拖运时间：{{info.tow_time }}</p>
                <p>拖运状态：{{ info.StatusTxt }}</p>
                <p>拖运备注：{{info.remark }}</p>
            </div>
        </div>
         <div class="orderInfo">
            <p class="orderInfo_p">合作运输车辆信息：</p>
            <div class="InfoList_a" v-for="item in cooperationCarList" :key="item.id">
                <p>合作车辆：{{item.plate_number }}——{{item.brand_model}}</p>
                <div class="infoBottom" >
                    <span>合作司机：{{item.realName}}</span>
                    <span class="photo" @click="callPhone(item.phone)">拨打电话</span>
                </div>
            </div>
        </div>
          <div class="orderInfo">
            <p class="orderInfo_p">被拖车辆信息：</p>
            <div class="InfoList">
                <p>拖车联系人：{{ TowedVehicle.carOwner }}</p>
                <p>拖车联系电话：{{ TowedVehicle.carOwnerPhone }}</p>
                <p>拖车地址：{{TowedVehicle.address }}</p>
                <p>拖运车辆备注：{{ TowedVehicle.shipping_note }}</p>
                <p>拖运车车牌号：{{ TowedVehicle.carNumberPlate }}</p>
            </div>
        </div>
        <div class="btnList">
            <div class="long_btn" @click="ReceivingOrders()" v-if="info.status == '10'">接单</div>
            
            <div class="long_btn" @click="start()" v-if="info.status == '25'">发车</div>
            <div class="long_btn" @click="autonavi(item)" v-if="gps">导航</div>
            
         
            <!-- <div class="long_btn" @click="goStartTransportation()" >运输前核验并开始运输</div>
            <div class="long_btn" @click="goReturnJourney()" v-if="info.status == '30'">无法拖运，返程</div> -->
             <div class="long_btn" @click="goStartTransportation()" v-if="info.status == '30'">运输前核验并开始运输</div>
            <div class="long_btn" @click="goReturnJourney()" v-if="info.status == '30'">无法拖运，返程</div>
            <!-- <div class="btn_box" >
                
            </div> -->
             <!-- <div class="btn_box"  v-if="info.status == '30'">
                <div class="short_btn" @click="goStartTransportation()">运输前核验并开始运输</div>
                <div class="short_btn" @click="goReturnJourney()">无法拖运，返程</div>
            </div> -->
            
           
            <div class="long_btn" v-if="info.status == '40'" @click="goArrive()">到达拆解厂，到场核验</div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
export default {
    data(){
        return{
            info:'',//托运订单信息：
            cooperationCarList:[],//托运订单信息：
            TowedVehicle:[],//托运订单信息：
            gps:'',
            address:'',
            
        }
    },
    created(){
        
        this.getData()
    },
    methods:{

    
        getData(){
             let data = encipherMent( JSON.stringify({id:this.$route.query.id}))
        let param = new URLSearchParams();
        param.append("value",data);
             this.$http.post('/index.php/dispatch_self_send/self_job_info',param).then(res=>{
              let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
            console.log(result)
            if(result.code == 0){
                this.info = result.data.info //托运订单信息：
                this.cooperationCarList = result.data.hezo //托运订单信息：
                this.TowedVehicle = result.data.beiTuoYun //被托运车
                if(result.data.beiTuoYun.gps){
                    this.gps = result.data.beiTuoYun.gps
                }
                this.address = result.data.beiTuoYun.address
            }
        })
        },
        autonavi() {
            //console.log(this.address)
            window.location.href = `https://apis.map.qq.com/uri/v1/routeplan?type=drive&to=${this.address}&tocoord=${this.gps}&policy=1&referer=WYZBZ-SEE3X-SYZ4N-7W7QQ-J55VJ-7JFB4`;
        },
        //拨打电话
        callPhone(a){
            window.location.href = 'tel://' + a
        },
        //接单
        ReceivingOrders(){
             let data = encipherMent( JSON.stringify({id:this.$route.query.id}))
        let param = new URLSearchParams();
        param.append("value",data);
            this.$http.post('/index.php/dispatch_self_send/accept',param).then(res=>{
                  let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
                if(result.code == 0){
                    Toast.success(result.msg)
                        this.getData()


                    // this.status_a ='1'
                }else{
                    Toast.fail(result.msg)
                }
            })
        },
        //发车
        start(){
             let data = encipherMent( JSON.stringify({id:this.$route.query.id}))
        let param = new URLSearchParams();
        param.append("value",data);
             this.$http.post('/index.php/dispatch_self_send/depart',param).then(res=>{
                 let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
                if(result.code == 0){
                    Toast.success(result.msg)
                        this.getData()

                    // this.status_a ='1'
                }else{
                    Toast.fail(result.msg)
                }
            })
        },
        //返程
        goReturnJourney(){
            this.$router.push({path:'/returnJourney',query:{id:this.$route.query.dispatch_id}})
        },
        //到达
        goArrive(){
            this.$router.push({path:'/arrive',query:{id:this.$route.query.dispatch_id}})
            
        },
        //运输前核验并开始运输
        goStartTransportation(){
            this.$router.push({path:'/StartTransportation',query:{id:this.$route.query.dispatch_id}})
        },
        goUp(){
            this.$router.go('-1')
        }


    }
}
</script>
<style lang="scss" scoped> 
    .headers {
      padding: 0 20px;
      background-color: cadetblue;
      height: 1rem;
      line-height: 1rem;
      color: white;
      font-size: 0.4rem;
      display: flex;

      justify-content: space-between;
      span {
        display: inline-block;
      }
      span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
      }
    }
    .orderDetail{
        .orderInfo{
            width: 95%;
            margin: 0 auto;
            border-bottom:1px solid;
            .orderInfo_p{
                font-size: 0.38rem;
            }
            .InfoList{
                margin-left: 1rem;
                font-size: 0.38rem;

                p{
                font-size: 0.38rem;

                }
              
            }
            .InfoList_a{
                margin-left: 1rem;
                font-size: 0.38rem;
                margin-bottom: 0.5rem;
                border-bottom: 1px dashed;

                p{
                font-size: 0.38rem;

                }
                .infoBottom{
                    margin:0.5rem auto;
                    .photo{
                        font-size: 0.35rem;
                        float: right;
                        // margin-right: 2rem;
                        height: 0.5rem;
                        line-height: 0.5rem;
                        width: 2rem;
                        text-align: center;
                        border: 1px solid;
                        border-radius: 5px;
                    }
                }
            }
        }
        .btnList{
            // height: 5rem;
            width: 95%;
            // border: 1px solid;
            margin: 0.5rem auto;
            .long_btn{
                width: 100%;
                height: 0.8rem;
                line-height: 0.8rem;
                text-align: center;
                color: white;
                font-size: 0.38rem;
                background-color: rgba(0, 153, 255, 1);
                border-radius:5px ;
                margin-top: 0.5rem;
            }
            .btn_box{
                display: flex;
                justify-content:space-between;
                margin-top: 0.5rem;

                .short_btn{
                // display: inline-block;
                width: 49%;
                height: 0.8rem;
                line-height: 0.8rem;
                text-align: center;
                color: white;
                font-size: 0.35rem;
                background-color: rgba(0, 153, 255, 1);
                border-radius:5px ;
            }
            }
            
        }
    }
</style>